import React from "react";
import { Link } from "gatsby";
import {
  Row, Card, Col, Container
} from "react-bootstrap";
import {
  kitchenware, kitchenwareContent, kitchenwareTitle,
  kitchenwareCard, cardImg, cardBody, kitchenwareSubtitle,
  cardContent, cardTitle, cardLink
} from "./style.mod.scss";
import getImagePath from "helpers/get-image-path";
import ArrowRight from "assets/svgs/arrow-right.svg";


const Kitchenware = ({ data }) => {
  const title = data?.title;
  const subtitle = data?.subtitle;
  const kitchenWareData = data?.kitchenWareData;

  return (
    <div className={kitchenware}>
      <Container>
        <Row>
          <Col lg="4" md="12" sm="12">
            <div className={kitchenwareContent}>
              <h3 className={kitchenwareSubtitle}>{ subtitle }</h3>
              <h1 className={kitchenwareTitle}>{ title }</h1>
            </div>
          </Col>
          {
            kitchenWareData?.map((module) => {
              const { title, url, mainimage } = module;
              return(
                <Col key={module._key} xs="6" md="6" lg="2">
                  <Card className={kitchenwareCard} >
                    <Link className={cardLink} to={url}>
                      <Card.Img className={cardImg} srcSet={getImagePath(mainimage, true)} alt={mainimage.alt}/>
                      <Card.Body className={cardBody}>
                        <div className={cardContent}>
                          <Card.Title className={cardTitle}>{title}
                            <ArrowRight/>
                          </Card.Title>
                        </div>
                      </Card.Body>
                    </Link>
                  </Card>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </div>
  );
};

export default Kitchenware;

// extracted by mini-css-extract-plugin
export var kitchenware = "style-mod--kitchenware--3Kw7u";
export var kitchenwareContent = "style-mod--kitchenware-content--3XPlb";
export var kitchenwareSubtitle = "style-mod--kitchenware-subtitle--2uv2c";
export var kitchenwareTitle = "style-mod--kitchenware-title--2bmHF";
export var cardContent = "style-mod--card-content--OF9Ar";
export var kitchenwareCard = "style-mod--kitchenware-card--3aeI-";
export var cardImg = "style-mod--card-img--3qx7F";
export var cardBody = "style-mod--card-body--e7urU";
export var cardTitle = "style-mod--card-title--1CsMV";
export var cardLink = "style-mod--card-link--M26wX";